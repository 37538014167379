import React from "react";
import Footer from "../../../components/Footer/Footer";
import CompanyLeftNav from "../../../components/TwoColumnLeftNav/CompanyLeftNav";

const Office = () => {
	const OfficeJaContent = [
		{
			title: "本社",
			name: "本社",
			code: "〒101-0045",
			address: "東京都千代田区神田鍛冶町3丁目4番地 oak神田鍛冶町3F",
			tel: "TEL.03-3254-8111(代)",
			telLink: "tel:03-3254-8111",
			fax: "FAX.03-3254-8119",
			src:
				"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.3211448855836!2d139.7689546151895!3d35.69371403689478!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c0238860b07%3A0x84df734d29f735a9!2zb2Fr56We55Sw6Y2b5Ya255S644OT44Or!5e0!3m2!1sja!2sjp!4v1615533269662!5m2!1sja!2sjp",
		},
		{
			title: "東北",
			name: "仙台営業所",
			code: "〒989-1304",
			address: "宮城県柴田郡村田町西ケ丘25-1",
			tel: "TEL.0224-83-4781(代)",
			telLink: "tel:0224-83-4781",
			fax: "FAX.0224-83-4770",
			src:
				"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d784.7855416600387!2d140.70706813491825!3d38.113626834370564!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f8a395b70ebaec5%3A0x8b15997be550b934!2z44ix5L2Q6Jek6KiI6YeP5Zmo6KO95L2c5omAIOWuruWfjuW3peWgtA!5e0!3m2!1sja!2sjp!4v1619579535681!5m2!1sja!2sjp",
		},
		{
			title: "関東",
			name: "東京営業所",
			code: "〒101-0045",
			address: "東京都千代田区神田鍛冶町3丁目4番地 oak神田鍛冶町3F",
			tel: "TEL.03-3254-8111(代)",
			telLink: "tel:03-3254-8111",
			fax: "FAX.03-3254-8119",
			src:
				"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.3211448855836!2d139.76895461518947!3d35.69371403689478!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c0238860b07%3A0x84df734d29f735a9!2zb2Fr56We55Sw6Y2b5Ya255S644OT44Or!5e0!3m2!1sja!2sjp!4v1615533638067!5m2!1sja!2sjp",
		},
		{
			title: "中部",
			name: "名古屋営業所",
			code: "〒460-0011",
			address: "名古屋市中区大須1-3-16",
			tel: "TEL.052-204-1234(代)",
			telLink: "tel:052-204-1234",
			fax: "FAX.052-204-1123",
			src:
				"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3261.7918222251524!2d136.89211191517668!3d35.161812265890056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6003772f74c26941%3A0xdf3c3be5de0b437c!2z44CSNDYwLTAwMTEg5oSb55-l55yM5ZCN5Y-k5bGL5biC5Lit5Yy65aSn6aCI77yR5LiB55uu77yT4oiS77yR77yW!5e0!3m2!1sja!2sjp!4v1615533724228!5m2!1sja!2sjp",
		},
		{
			title: "関西",
			name: "大阪営業所",
			code: "〒540-0037",
			address: "大阪市中央区内平野町2-1-10",
			tel: "TEL.06-6944-0921(代)",
			telLink: "tel:06-6944-0921",
			fax: "FAX.06-6944-0926",
			src:
				"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3280.7082035207613!2d135.51149411516562!3d34.68731329144296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e72656cf859d%3A0xd2f7490be51b0dc3!2z44CSNTQwLTAwMzcg5aSn6Ziq5bqc5aSn6Ziq5biC5Lit5aSu5Yy65YaF5bmz6YeO55S677yS5LiB55uu77yR4oiS77yR77yQ!5e0!3m2!1sja!2sjp!4v1615533820488!5m2!1sja!2sjp",
		},
		{
			title: "北陸",
			name: "富山営業所",
			code: "〒939-8211",
			address: "富山県富山市二口町5-2-3",
			tel: "TEL.076-494-3088(代)",
			telLink: "tel:076-494-3088",
			fax: "FAX.076-494-3090",
			src:
				"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3200.201079271983!2d137.20122611521293!3d36.66966608273649!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5ff7903140f435b1%3A0xa0fc0bba806beaa7!2z44CSOTM5LTgyMTEg5a-M5bGx55yM5a-M5bGx5biC5LqM5Y-j55S677yV5LiB55uu77yS4oiS77yT!5e0!3m2!1sja!2sjp!4v1615533900505!5m2!1sja!2sjp",
		},
		{
			title: "九州",
			name: "福岡営業所",
			code: "〒812-0018",
			address: "福岡市博多区住吉4-3-2　博多エイトビル4F",
			tel: "TEL.092-451-1685(代)",
			telLink: "tel:092-451-1685",
			fax: "FAX.092-451-1688",
			src:
				"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3323.7949449609046!2d130.4139036151405!3d33.584672949672076!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x354191bb884ad3cd%3A0x2fffd640eb07b068!2z44CSODEyLTAwMTgg56aP5bKh55yM56aP5bKh5biC5Y2a5aSa5Yy65L2P5ZCJ77yU5LiB55uu77yT4oiS77ySIOWNmuWkmuOCqOOCpOODiOODk-ODqyA0Rg!5e0!3m2!1sja!2sjp!4v1615533965431!5m2!1sja!2sjp",
		},
		{
			title: "東京工場",
			name: "東京工場",
			code: "〒174-0072",
			address: "東京都板橋区南常盤台2-9-18",
			tel: "",
			telLink: "",
			fax: "",
			src:
				"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3237.7233833850814!2d139.68359751563517!3d35.75760313338632!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018ed2e9c842af9%3A0x62602a10190adb47!2z44CSMTc0LTAwNzIg5p2x5Lqs6YO95p2_5qmL5Yy65Y2X5bi455uk5Y-w77yS5LiB55uu77yZ4oiS77yR77yY!5e0!3m2!1sja!2sjp!4v1619434321615!5m2!1sja!2sjp",
		},
		{
			title: "宮城工場",
			name: "宮城工場",
			code: "〒989-1304",
			address: "宮城県柴田郡村田町西ケ丘25-1",
			tel: "",
			telLink: "",
			fax: "",
			src:
				"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3139.143775575936!2d140.70542541568005!3d38.113589400365896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f8a395b707abef7%3A0xc6a23e5116827bd5!2z44CSOTg5LTEzMDUg5a6u5Z-O55yM5p-055Sw6YOh5p2R55Sw55S65p2R55Sw6KW_44Kx5LiY77yS77yV4oiS77yR!5e0!3m2!1sja!2sjp!4v1619434448615!5m2!1sja!2sjp",
		},
		{
			title: "配送センター",
			name: "朝霞センター",
			code: "〒351-0012",
			address: "埼玉県朝霞市栄町1-3-38",
			tel: "",
			telLink: "",
			fax: "",
			src:
				"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3236.3577748686284!2d139.5951738156359!3d35.79114913154249!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018e96fb7dcd18b%3A0xe50efc2620a63891!2z44CSMzUxLTAwMTIg5Z-8546J55yM5pyd6Zye5biC5qCE55S677yR5LiB55uu77yT4oiS77yT77yY!5e0!3m2!1sja!2sjp!4v1619434504436!5m2!1sja!2sjp",
		},
	];

	const OfficeEnContent = [
		{
			title: "Head Office ",
			name: "Head Office ",
			code: "",
			address: "3-4, Kanda-kajicho, Chiyoda-ku, Tokyo 101-0045 Japan",
			tel: "",
			telLink: "",
			fax: "",
			src:
				"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.3211448856196!2d139.7689546156342!3d35.693714036893894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c0238860b07%3A0x84df734d29f735a9!2zb2Fr56We55Sw6Y2b5Ya255S644OT44Or!5e0!3m2!1sen!2sjp!4v1619504009621!5m2!1sen!2sjp",
		},
		{
			title: "Tohoku",
			name: "Sendai Branch",
			code: "",
			address: "25-1, Nishigaoka, Murata-machi, Shibata-gun, Miyagi 989-1304",
			tel: "",
			telLink: "",
			fax: "",
			src:
				"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d466.63789876176907!2d140.70765309400957!3d38.11337267994004!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f8a395b70ebaec5%3A0x8b15997be550b934!2z44ix5L2Q6Jek6KiI6YeP5Zmo6KO95L2c5omAIOWuruWfjuW3peWgtA!5e0!3m2!1sen!2sjp!4v1619579954628!5m2!1sen!2sjp",
		},
		{
			title: "Chubu",
			name: "Nagoya Branch",
			code: "",
			address: "1-3-16, Osu, Naka-ku, Nagoya-shi, Aichi 460-0011",
			tel: "",
			telLink: "",
			fax: "",
			src:
				"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3261.791822225185!2d136.8921119156244!3d35.16181226588923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6003772f74c26941%3A0xdf3c3be5de0b437c!2s1-ch%C5%8Dme-3-16%20%C5%8Csu%2C%20Naka%20Ward%2C%20Nagoya%2C%20Aichi%20460-0011!5e0!3m2!1sen!2sjp!4v1619502832699!5m2!1sen!2sjp",
		},
		{
			title: "Kansai",
			name: "Osaka Branch",
			code: "",
			address: "2-1-10, Uchihiranomachi, Chuo-ku, Osaka-shi, Osaka 540-0037",
			tel: "",
			telLink: "",
			fax: "",
			src:
				"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3280.7082035207995!2d135.511494115616!3d34.687313291442!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e72656cf859d%3A0xd2f7490be51b0dc3!2s2-ch%C5%8Dme-1-10%20Uchihiranomachi%2C%20Chuo%20Ward%2C%20Osaka%2C%20540-0037!5e0!3m2!1sen!2sjp!4v1619503327613!5m2!1sen!2sjp",
		},
		{
			title: "Hokuriku",
			name: "Toyama Branch",
			code: "",
			address: "5-2-3, Futakuchimachi, Toyama-shi, Toyama 939-8211",
			tel: "",
			telLink: "",
			fax: "",
			src:
				"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3200.201079272019!2d137.20122611565213!3d36.66966608273563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5ff7903140f435b1%3A0xa0fc0bba806beaa7!2z44CSOTM5LTgyMTEgVG95YW1hLCBGdXRha3VjaGltYWNoaSwgNS1jaMWNbWXiiJLvvJLiiJIz!5e0!3m2!1sen!2sjp!4v1619503546354!5m2!1sen!2sjp",
		},
		{
			title: "Kyushu",
			name: "Fukuoka Branch",
			code: "",
			address: "4-3-2, Sumiyoshi, Hakata-ku, Fukuoka-shi, Fukuoka 812-0018",
			tel: "",
			telLink: "",
			fax: "",
			src:
				"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3323.795730771981!2d130.41389241559673!3d33.584652549672256!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x354191bb89b54c07%3A0x9ffcf9bb29bc2a62!2s4-ch%C5%8Dme-3-2%20Sumiyoshi%2C%20Hakata%20Ward%2C%20Fukuoka%2C%20812-0018!5e0!3m2!1sen!2sjp!4v1619503869754!5m2!1sen!2sjp",
		},
		{
			title: "Tokyo Factory",
			name: "Tokyo Factory",
			code: "",
			address: "2-9-18, Minamitokiwadai, Itabashi-ku, Tokyo 174-0072",
			tel: "",
			telLink: "",
			fax: "",
			src:
				"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3237.7233833850814!2d139.68359751563517!3d35.75760313338632!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018ed2e9c842af9%3A0x62602a10190adb47!2s2-ch%C5%8Dme-9-18%20Minamitokiwadai%2C%20Itabashi%20City%2C%20Tokyo%20174-0072!5e0!3m2!1sen!2sjp!4v1619504442538!5m2!1sen!2sjp",
		},
		{
			title: "Miyagi Factory",
			name: "Miyagi Factory",
			code: "",
			address: "25-1, Nishigaoka, Murata-cho, Shibata-gun, Miyagi 989-1304",
			tel: "",
			telLink: "",
			fax: "",
			src:
				"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3139.143775575936!2d140.70542541568005!3d38.113589400365896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f8a395b707abef7%3A0xc6a23e5116827bd5!2sNishigaoka-25-1%20Murata%2C%20Shibata%20District%2C%20Miyagi%20989-1305!5e0!3m2!1sen!2sjp!4v1619504623095!5m2!1sen!2sjp",
		},
		{
			title: "Delivery Center / Warehouse",
			name: "Asaka Center",
			code: "",
			address: "1-3-38, Sakae-cho, Asaka-shi, Saitama 351-0012",
			tel: "",
			telLink: "",
			fax: "",
			src:
				"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3236.357774868628!2d139.5951738156359!3d35.791149131542504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018e96fb7dcd18b%3A0xe50efc2620a63891!2s1-ch%C5%8Dme-3-38%20Sakaech%C5%8D%2C%20Asaka%2C%20Saitama%20351-0012!5e0!3m2!1sen!2sjp!4v1619504839839!5m2!1sen!2sjp",
		},
	];

	const OfficeJaInfo = () => {
		return (
			<div className="office_main-content content-container">
				{OfficeJaContent.map((OfficeItem, index) => {
					return (
						<div className="office_inner" key={index} itemScope itemType="http://schema.org/Corporation">
							<ul>
								<li>
									<h2 className="office_title office_title_h2">
										{OfficeItem.title}
									</h2>
								</li>
							</ul>
							<div className="flex-container">
								<div className="flex-1">
									<p className="office_p medium office_title_officename" itemProp="name">
										{OfficeItem.name}
									</p>
								</div>
								<div className="flex-2" itemProp="address" itemScope
									 itemType="https://schema.org/PostalAddress">
									<address className="office_address">
										<div className="office_address_box_info">
											<p className="office_p medium" itemProp="postalCode">{OfficeItem.code}</p>
											<p className="office_p medium"
											   itemProp="streetAddress">{OfficeItem.address}</p>
										</div>
										<div className="office_phonenumber_box">
											<p className="office_p medium" itemProp="telephone"><a
												href={OfficeItem.telLink}>{OfficeItem.tel}</a></p>
											<p className="office_p medium" itemProp="faxNumber">{OfficeItem.fax}</p>
										</div>
									</address>
								</div>
								<div className="flex-3 gmap" itemProp="location" itemScope
									 itemType="https://schema.org/Place">
									<iframe
										title="gmap"
										src={OfficeItem.src}
										width="300"
										height="300"
										style={{border: `0`}}
										allowFullScreen=""
										loading="lazy"
										itemProp="hasMap"
									/>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		);
	}

	const OfficeEnInfo = () => {
		return (
			<div className="office_main-content content-container">
				{OfficeEnContent.map((OfficeItem, index) => {
					return (
						<div className="office_inner" key={index} itemScope itemType="http://schema.org/Corporation">
							<ul>
								<li>
									<h2 className="office_title office_title_h2">
										{OfficeItem.title}
									</h2>
								</li>
							</ul>
							<div className="flex-container">
								<div className="flex-1">
									<p className="office_p medium office_title_officename" itemProp="name">
										{OfficeItem.name}
									</p>
								</div>
								<div className="flex-2" itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
									<address className="office_address">
										<div className="office_address_box_info">
											<p className="office_p medium" itemProp="postalCode">{OfficeItem.code}</p>
											<p className="office_p medium" itemProp="streetAddress">{OfficeItem.address}</p>
										</div>
										<div className="office_phonenumber_box">
											<p className="office_p medium" itemProp="telephone"><a href={OfficeItem.telLink}>{OfficeItem.tel}</a></p>
											<p className="office_p medium" itemProp="faxNumber">{OfficeItem.fax}</p>
										</div>
									</address>
								</div>
								<div className="flex-3 gmap" itemProp="location" itemScope itemType="https://schema.org/Place">
									<iframe
										title="gmap"
										src={OfficeItem.src}
										width="300"
										height="300"
										style={{ border: `0` }}
										allowFullScreen=""
										loading="lazy"
										itemProp="hasMap"
									/>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		);
	};

	const pathName =
		typeof window !== "undefined" ? window.location.pathname : "";

	return (
		<>
			<article className="company-info two-column-left-nav">
				<CompanyLeftNav />
				<section className="main-content">
					{pathName.indexOf("/en") !== -1 ? <OfficeEnInfo /> : <OfficeJaInfo />}
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Office;
